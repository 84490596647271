document.addEventListener('turbolinks:load', () => {
  const mobileView = document.getElementById('mobile-view')
  const desktopWarning = document.getElementById('desktop-warning')
  const dismissLink = document.getElementById('dismiss-link')

  mobileView.classList.toggle('hidden')
  desktopWarning.classList.toggle('hidden')

  const body = document.getElementsByTagName('body')[0]
  body.style.backgroundColor = '#FAFAFA'

  dismissLink.addEventListener('click', () => {
    mobileView.classList.toggle('hidden')
    desktopWarning.remove()
    body.style.backgroundColor = '#FFFFFF'
  })
})